.call-buton .cc-calto-action-ripple {
  z-index: 99999;
  position: fixed;
  left: 3rem;
  bottom: 3rem;
  background: #ec8b00;
  width: 5rem;
  height: 5rem;
  padding: 1.4rem;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #ffffff;
  -webkit-animation: cc-calto-action-ripple 0.6s linear infinite;
  animation: cc-calto-action-ripple 0.6s linear infinite;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}
.call-buton .cc-calto-action-ripple i {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  font-size: 2.2rem;
}
.call-buton .cc-calto-action-ripple:hover i {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

@-webkit-keyframes cc-calto-action-ripple {
  0% {
    -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2),
      0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2),
      0 0 0 10px rgba(236, 139, 0, 0.2);
    box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2),
      0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2),
      0 0 0 10px rgba(236, 139, 0, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2),
      0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2),
      0 0 0 20px rgba(236, 139, 0, 0);
    box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2),
      0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2),
      0 0 0 20px rgba(236, 139, 0, 0);
  }
}

@keyframes cc-calto-action-ripple {
  0% {
    -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2),
      0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2),
      0 0 0 10px rgba(236, 139, 0, 0.2);
    box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2),
      0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2),
      0 0 0 10px rgba(236, 139, 0, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2),
      0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2),
      0 0 0 20px rgba(236, 139, 0, 0);
    box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2),
      0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2),
      0 0 0 20px rgba(236, 139, 0, 0);
  }
}

/* span.num{
		position: absolute;
    color: #ec8b00;
    left: -30%;
    bottom: -50%;
	} */
